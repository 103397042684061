var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggle = exports.remove = exports.add = void 0;

function _classesOf(element) {
  return element.className.trim().split(/\s+/);
}

function add(element) {
  var toAdd = [];

  for (var _i = 1; _i < arguments.length; _i++) {
    toAdd[_i - 1] = arguments[_i];
  }

  element.className = _classesOf(element).filter(function (classname) {
    return toAdd.indexOf(classname) === -1;
  }).concat(toAdd).join(" ");
}

exports.add = add;

function remove(element) {
  var toRemove = [];

  for (var _i = 1; _i < arguments.length; _i++) {
    toRemove[_i - 1] = arguments[_i];
  }

  element.className = _classesOf(element).filter(function (classname) {
    return toRemove.indexOf(classname) === -1;
  }).join(" ");
}

exports.remove = remove;

function toggle(element, classname, adding) {
  if (adding) {
    add(element, classname);
  } else {
    remove(element, classname);
  }
}

exports.toggle = toggle;
export default exports;
export const __esModule = exports.__esModule;
const _toggle = exports.toggle,
      _remove = exports.remove,
      _add = exports.add;
export { _toggle as toggle, _remove as remove, _add as add };